document.addEventListener('DOMContentLoaded', function () {
    // ボタンクリックイベントのリスナーを追加
    const closeButton = document.querySelector('.js-banner-close');
    const banner = document.querySelector('.to-recruit__banner');

    // ボタンが存在するか確認
    if (closeButton && banner) {
        closeButton.addEventListener('click', function () {
            // クラス "-close" を追加
            banner.classList.add('-close');
        });
    }
});

// gsap.registerPlugin(ScrollTrigger);

// function initParallaxAnimation() {
//     gsap.utils.toArray('.js-concept-img').forEach(function (container) {
//         let image = container.querySelector('img');

//         gsap.to(image, {
//             y: () => image.offsetHeight - container.offsetHeight,
//             ease: 'none',
//             scrollTrigger: {
//                 trigger: container,
//                 scrub: true,
//                 pin: false,
//                 // markers: true,
//                 start: '0% 80%', // ビューポートの80%位置で開始
//                 end: '100% 80%', // ビューポートの80%位置で終了
//                 invalidateOnRefresh: true, // リサイズやリフレッシュで計算を更新
//             },
//         });
//     });
// }

// // 初期化関数を呼び出し
// initParallaxAnimation();

// // ページロード後にScrollTriggerをリフレッシュ
// window.addEventListener('load', () => {
//     ScrollTrigger.refresh();
// });

// // ウィンドウリサイズ時にもリフレッシュ
// window.addEventListener('resize', () => {
//     ScrollTrigger.refresh();
// });
